<template>
    <base-btn
        v-if="!hasProducts"
        :to="{ name: 'CartIndex' }"
        variant="link"
        class="nav-link"
    >
        <icon-cart notification="0" />
    </base-btn>
    <base-dropdown
        v-else
        ref="dropdown"
        no-caret
        right
        variant="none"
        class="dropdown-cart"
        toggle-class="nav-link"
        @click-toggle="$route.name !== 'CartIndex' && $router.push({ name: 'CartIndex' })"
        @mounted="$emitter.emit('mounted-mini-cart')"
        @mouseover.native="onDropdownMouseover($refs.dropdown)"
        @mouseleave.native="onDropdownMouseleave($refs.dropdown)"
    >
        <template #toggle>
            <icon-cart :notification="notification" />
        </template>
        <template #menu>
            <div class="cart-header-products">
                <base-lines-product
                    v-for="(product, index) in cart.products"
                    :key="`header-cart-${index}`"
                    :name="product.name"
                    :image-src="product.image.src"
                    :total-price="product.subtotal"
                    :currency="product.currency"
                    :quantity="product.quantity"
                    :unit-price="product.unit_price"
                    :price-conversion="product.priceConversion"
                    :display-quantity="false"
                />
            </div>
            <ul class="cart-header-c2a">
                <li v-if="cart.displayTotal">
                    <div>{{ $t('views_cart_loadcart_0005') }}:</div>
                    <div
                        v-if="cart.priceConversion"
                        class="text-secondary"
                    >
                        {{ cart.priceConversion.total_order }}
                        {{ cart.priceConversion.currency }}
                    </div>
                    <div>
                        {{ cart.totalProductsFee }}
                        {{ cart.currency }}
                    </div>
                </li>
                <li>
                    <base-btn
                        variant="primary"
                        @click.native="onClickBtnCart()"
                    >
                        {{ $t('views_cart_minicart_0001') }}
                    </base-btn>
                </li>
                <li
                    v-if="messageFreeDelivery"
                    class="item-delivery"
                >
                    <base-icon name="gift" />
                    <div
                        class="text-strong-success"
                        v-html="messageFreeDelivery"
                    />
                </li>
            </ul>
        </template>
    </base-dropdown>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import IconCart from './IconCart.vue';

const BaseDropdown = defineAsyncComponent(() => import('./BaseDropdown.vue'));
const BaseLinesProduct = defineAsyncComponent(() => import('./BaseLinesProduct.vue'));

export default {
    name: 'TheHeaderNavQuickBtnCart',

    components: {
        IconCart,
        BaseDropdown,
        BaseLinesProduct,
    },

    computed: {
        cart() {
            return this.$store.state.header.cart;
        },
        hasProducts() {
            return this.cart?.products?.length;
        },
        notification() {
            if (!this.hasProducts) return 0;
            return this.cart.products.reduce((acc, item) => acc + item.quantity, 0);
        },
        messageFreeDelivery() {
            const { left, currency } = (this.cart && this.cart.costAlert && this.cart.costAlert[0])
                || { left: undefined, currency: undefined };

            if (typeof left === 'undefined' || typeof currency === 'undefined') return false;

            const isFreeDelivery = !(left > 0);
            const messageFreeDeliveryCost = this.$t('views_cart_free_shipping_cost', { freeShippingCost: `${left} ${currency}` });
            const messageFreeDelivery = this.$t('views_cart_loadcart_0043');

            return (!isFreeDelivery) ? messageFreeDeliveryCost : `<strong>${messageFreeDelivery}</strong>`;
        },
    },

    mounted() {
        this.dropdownSetTimeoutId = null;

        this.$emitter.on('show-mini-cart', () => {
            if (this.$refs.dropdown) {
                this.$refs.dropdown.show();
            } else {
                this.$emitter.on('mounted-mini-cart', () => {
                    this.$refs.dropdown.show();
                });
            }
        });

        this.$emitter.on('hide-mini-cart', () => {
            this.$refs.dropdown.hide();
        });
    },

    methods: {
        onDropdownMouseover(dropdown) {
            clearTimeout(this.dropdownSetTimeoutId);

            dropdown.show();
        },
        onDropdownMouseleave(dropdown) {
            this.dropdownSetTimeoutId = setTimeout(() => {
                dropdown.hide();
            }, 150);
        },
        onClickBtnCart() {
            this.$refs.dropdown.hide();

            if (['CartIndex'].includes(this.$route.name)) return;

            this.$router.push({ name: 'CartIndex' });
        },
    },
};
</script>

<style lang="scss">
@import "./../scss/required";

.dropdown-cart {
    .dropdown-menu {
        padding: 0;

        &.show {
            display: none;
        }

        @include media-breakpoint-up(md) {
            min-width: 350px;

            &.show {
                display: block;
            }
        }
    }

    .cart-header-products {
        max-height: 60vh;
        overflow-x: hidden;
        overflow-y: scroll;
        font-size: $font-size-sm;
        border-bottom: 1px solid $hr-border-color;

        .lines-product {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .cart-header-c2a {
        padding: $dropdown-item-padding-x;
        margin-bottom: 0;
        background-color: $gray-100;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            + li {
                margin-top: $spacer;
            }
        }

        .btn-primary {
            margin-left: auto;
        }

        .item-delivery {
            align-items: flex-start;
            justify-content: flex-start;

            .icon {
                flex: 0 0 auto;
                margin-right: $spacer;
            }
        }
    }
}
</style>
