<template>
    <base-icon
        classes="lg"
        :data-notification="notification"
    >
        <BaseSvg name="cart" />
        <span class="visually-hidden">{{ $t('views_cart_loadcart_0001') }}</span>
    </base-icon>
</template>

<script>
export default {
    name: 'IconCart',

    props: {
        notification: {
            type: [Number, String],
            default: '',
        },
    },
};
</script>
