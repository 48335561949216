var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("nav", { staticClass: "nav-categories" }, [
    _c(
      "ul",
      { ref: "menu", staticClass: "list-categories" },
      _vm._l(_vm.links, function (item) {
        return _c(
          "li",
          {
            key: item.text,
            class: {
              "list-categories-link": !item.accordion,
              active: item.active,
            },
          },
          [
            item.children
              ? _c("div", { staticClass: "list-categories-accordion-body" }, [
                  _c(
                    "ul",
                    { staticClass: "list-categories" },
                    _vm._l(item.children, function (category, indexCategory) {
                      return _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "hover-intent",
                              rawName: "v-hover-intent",
                              value:
                                category.children &&
                                _setup.toggleSubmenu.bind(null, indexCategory),
                              expression:
                                "category.children\n                            && toggleSubmenu.bind(null, indexCategory)",
                            },
                          ],
                          key: `header-categories-${indexCategory}`,
                          class: [
                            category.class,
                            _setup.active === indexCategory && "show-menu",
                          ],
                        },
                        [
                          _c(
                            category.domElementTag,
                            {
                              tag: "component",
                              style: category.style,
                              attrs: {
                                to:
                                  (_setup.$mediaBreakpointDown("lg") &&
                                    category.children &&
                                    !(_setup.active === indexCategory) &&
                                    _vm.$route.fullPath) ||
                                  category.href,
                                href: category.href,
                                title: category.title,
                                "active-class": "active",
                              },
                            },
                            [
                              category.imgUrl
                                ? _c("base-img", {
                                    attrs: {
                                      src: category.imgUrl,
                                      alt: category.title,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("strong", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(category.title) +
                                    "\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("base-icon", {
                                attrs: { name: "arrow-right" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _setup.active === indexCategory && category.children
                            ? _c("div", { staticClass: "dropdown-menu" }, [
                                _c("div", { staticClass: "container-fluid" }, [
                                  _c(
                                    "ul",
                                    { staticClass: "list-categories-l2" },
                                    _vm._l(
                                      category.children,
                                      function (subcategory, indexSubcategory) {
                                        return _c(
                                          "li",
                                          {
                                            key: `header-subcategories1-${indexSubcategory}`,
                                          },
                                          [
                                            _c(
                                              subcategory.domElementTag,
                                              {
                                                tag: "component",
                                                attrs: {
                                                  to: subcategory.href,
                                                  href: subcategory.href,
                                                  title: subcategory.title,
                                                  "active-class": "active",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(subcategory.title)
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                subcategory.href
                                                  ? _c("base-icon", {
                                                      attrs: {
                                                        name: "arrow-right",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            subcategory.children
                                              ? _c(
                                                  "ul",
                                                  {
                                                    staticClass:
                                                      "list-categories-l3",
                                                  },
                                                  _vm._l(
                                                    subcategory.children,
                                                    function (
                                                      subsubcategory,
                                                      indexSubsubcategory
                                                    ) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: `header-subcategories2-${indexSubsubcategory}`,
                                                        },
                                                        [
                                                          _c(
                                                            subsubcategory.domElementTag,
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                to: subsubcategory.href,
                                                                href: subsubcategory.href,
                                                                title:
                                                                  subsubcategory.title,
                                                                "active-class":
                                                                  "active",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    subsubcategory.title
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }