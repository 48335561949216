var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-icon",
    { attrs: { classes: "lg", "data-notification": _vm.notification } },
    [
      _c("BaseSvg", { attrs: { name: "cart" } }),
      _vm._v(" "),
      _c("span", { staticClass: "visually-hidden" }, [
        _vm._v(_vm._s(_vm.$t("views_cart_loadcart_0001"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }