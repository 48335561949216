<template>
    <nav class="nav-categories">
        <ul
            ref="menu"
            class="list-categories"
        >
            <li
                v-for="(item) in links"
                :key="item.text"
                :class="{
                    'list-categories-link': !item.accordion,
                    'active': item.active,
                }"
            >
                <div
                    v-if="item.children"
                    class="list-categories-accordion-body"
                >
                    <ul class="list-categories">
                        <li
                            v-for="(category, indexCategory) in item.children"
                            :key="`header-categories-${indexCategory}`"
                            v-hover-intent="category.children
                                && toggleSubmenu.bind(null, indexCategory)"
                            :class="[category.class, active === indexCategory && 'show-menu']"
                        >
                            <component
                                :is="category.domElementTag"
                                :to="(
                                    $mediaBreakpointDown('lg')
                                    && category.children
                                    && !(active === indexCategory)
                                    && $route.fullPath
                                ) || category.href"
                                :href="category.href"
                                :title="category.title"
                                :style="category.style"
                                active-class="active"
                            >
                                <base-img
                                    v-if="category.imgUrl"
                                    :src="category.imgUrl"
                                    :alt="category.title"
                                />

                                <strong>
                                    {{ category.title }}
                                </strong>

                                <base-icon name="arrow-right" />
                            </component>

                            <div
                                v-if="active === indexCategory && category.children"
                                class="dropdown-menu"
                            >
                                <div class="container-fluid">
                                    <ul class="list-categories-l2">
                                        <li
                                            v-for="(subcategory, indexSubcategory)
                                                in category.children"
                                            :key="`header-subcategories1-${indexSubcategory}`"
                                        >
                                            <component
                                                :is="subcategory.domElementTag"
                                                :to="subcategory.href"
                                                :href="subcategory.href"
                                                :title="subcategory.title"
                                                active-class="active"
                                            >
                                                <span>{{ subcategory.title }}</span>
                                                <base-icon
                                                    v-if="subcategory.href"
                                                    name="arrow-right"
                                                />
                                            </component>

                                            <ul
                                                v-if="subcategory.children"
                                                class="list-categories-l3"
                                            >
                                                <li
                                                    v-for="(subsubcategory, indexSubsubcategory)
                                                        in subcategory.children"
                                                    :key="`header-subcategories2-${
                                                        indexSubsubcategory
                                                    }`"
                                                >
                                                    <component
                                                        :is="subsubcategory.domElementTag"
                                                        :to="subsubcategory.href"
                                                        :href="subsubcategory.href"
                                                        :title="subsubcategory.title"
                                                        active-class="active"
                                                    >
                                                        {{ subsubcategory.title }}
                                                    </component>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script setup>
import {
    ref, watch,
    defineProps, defineEmits,
    onUnmounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { useHead } from '@unhead/vue';
import { useCurrentInstance } from '../composables/useCurrentInstance';

const props = defineProps({
    drawer: {
        type: Boolean,
        default: false,
    },
    links: {
        type: Array,
        default: () => [],
    },
});
const emit = defineEmits(['update:drawer']);

onUnmounted(() => {
    emit('update:drawer', false);
});

const accordion = ref(null);

const router = useRouter();
const onClickLinks = ($event, navigate, item, index) => {
    if (item.href) return router.resolve(item.href).href;
    if (router.currentRoute === (item.to || item.href)) {
        $event.preventDefault();
        return emit('update:drawer', false);
    }
    if (item.to) return navigate($event);

    return false;
};

const active = ref(null);
const activeStack = ref(new Set());
const { globalProperties: { $mediaBreakpointDown } } = useCurrentInstance();
const toggleSubmenu = (key, eventName) => {
    if ($mediaBreakpointDown('lg')) {
        if (eventName === 'click') (active.value = key);

        return;
    }

    if (typeof key !== 'number' && key === null) active.value = null;

    switch (eventName) {
    case 'mouseover-delayed': {
        const stack = [...activeStack.value];

        if (stack.length === 1 && key === stack[0]) {
            active.value = key;
        }

        break;
    }

    case 'mouseleave-delayed': {
        if (activeStack.value.size === 0) {
            active.value = null;
        }
        break;
    }

    case 'mouseover':
        activeStack.value.add(key);
        break;

    case 'mouseleave':
        activeStack.value.delete(key);
        break;

    default:
    case 'click':
        activeStack.value.clear();
        break;
    }
};

const route = useRoute();
watch(route, () => {
    toggleSubmenu(null);
    accordion.value = null;
    active.value = null;
    emit('update:drawer', false);
});

useHead({
    bodyAttrs: {
        class: {
            'body-nav-drawer': () => props.drawer,
        },
    },
});
</script>

<script>
export default {
    model: {
        prop: 'drawer',
        event: 'update:drawer',
    },
};
</script>

<style lang="scss">
@import "../scss/05-components/the-header-categories";
</style>
