var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.hasProducts
    ? _c(
        "base-btn",
        {
          staticClass: "nav-link",
          attrs: { to: { name: "CartIndex" }, variant: "link" },
        },
        [_c("icon-cart", { attrs: { notification: "0" } })],
        1
      )
    : _c("base-dropdown", {
        ref: "dropdown",
        staticClass: "dropdown-cart",
        attrs: {
          "no-caret": "",
          right: "",
          variant: "none",
          "toggle-class": "nav-link",
        },
        on: {
          "click-toggle": function ($event) {
            _vm.$route.name !== "CartIndex" &&
              _vm.$router.push({ name: "CartIndex" })
          },
          mounted: function ($event) {
            return _vm.$emitter.emit("mounted-mini-cart")
          },
        },
        nativeOn: {
          mouseover: function ($event) {
            return _vm.onDropdownMouseover(_vm.$refs.dropdown)
          },
          mouseleave: function ($event) {
            return _vm.onDropdownMouseleave(_vm.$refs.dropdown)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "toggle",
            fn: function () {
              return [
                _c("icon-cart", { attrs: { notification: _vm.notification } }),
              ]
            },
            proxy: true,
          },
          {
            key: "menu",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "cart-header-products" },
                  _vm._l(_vm.cart.products, function (product, index) {
                    return _c("base-lines-product", {
                      key: `header-cart-${index}`,
                      attrs: {
                        name: product.name,
                        "image-src": product.image.src,
                        "total-price": product.subtotal,
                        currency: product.currency,
                        quantity: product.quantity,
                        "unit-price": product.unit_price,
                        "price-conversion": product.priceConversion,
                        "display-quantity": false,
                      },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("ul", { staticClass: "cart-header-c2a" }, [
                  _vm.cart.displayTotal
                    ? _c("li", [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$t("views_cart_loadcart_0005")) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.cart.priceConversion
                          ? _c("div", { staticClass: "text-secondary" }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.cart.priceConversion.total_order) +
                                  "\n                    " +
                                  _vm._s(_vm.cart.priceConversion.currency) +
                                  "\n                "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.cart.totalProductsFee) +
                              "\n                    " +
                              _vm._s(_vm.cart.currency) +
                              "\n                "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "base-btn",
                        {
                          attrs: { variant: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onClickBtnCart()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("views_cart_minicart_0001")) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.messageFreeDelivery
                    ? _c(
                        "li",
                        { staticClass: "item-delivery" },
                        [
                          _c("base-icon", { attrs: { name: "gift" } }),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "text-strong-success",
                            domProps: {
                              innerHTML: _vm._s(_vm.messageFreeDelivery),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }