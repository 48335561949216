<template>
    <header>
        <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
                <div class="flex-grow-1 me-2">
                    <logo class="navbar-brand me-3" />

                    <button
                        v-if="!showAppBtnBack && showCategories()"
                        type="button"
                        class="navbar-toggler"
                        :aria-expanded="drawer ? 'true' : 'false'"
                        aria-label="Toggle navigation"
                        @click="drawer = !drawer"
                    >
                        <base-icon
                            v-if="drawer"
                            classes="xl"
                        >
                            <BaseSvg name="close" />
                        </base-icon>
                        <base-icon
                            v-else
                            classes="xl"
                        >
                            <BaseSvg name="filters" />
                        </base-icon>
                    </button>
                    <button
                        v-else
                        type="button"
                        class="navbar-toggler"
                        @click="$router.back()"
                    >
                        <base-icon
                            name="arrow-left"
                            class="icon-lg"
                        />
                    </button>
                </div>

                <the-header-nav-quick />

                <template v-if="$store.state.ui.switch.web.header.formSearch">
                    <the-header-search />
                </template>
            </div>
        </nav>
        <div
            class="the-header-menu"
            @click="onClickTheHeaderMenu($event)"
        >
            <div class="container-fluid">
                <the-header-categories
                    v-if="showCategories()"
                    v-model="drawer"
                    :links="links"
                />
            </div>
        </div>
    </header>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import TheHeaderNavQuick from './TheHeaderNavQuick.vue';
import Logo from './Logo.vue';
import TheHeaderCategories from './TheHeaderCategories.vue';
import { SOURCE } from '../utils/constants';

const TheHeaderSearch = defineAsyncComponent(() => import('./TheHeaderSearch.vue'));

export default {
    name: 'TheHeader',

    components: {
        Logo,
        TheHeaderSearch,
        TheHeaderNavQuick,
        TheHeaderCategories,
    },

    data: () => ({
        drawer: false,
        showAppBtnBack: false,
    }),

    computed: {
        links() {
            return this.linksSource
                .filter(({ country }) => !country
                    || country === this.$store.state.params.country.id)
                .map((link) => {
                    if (link.children === 'PRODUCTS') {
                        Object.assign(link, {
                            children: this.$store.state.header.categories,
                        });
                    }

                    Object.assign(link, {
                        active: link.activeRoutes?.includes(this.$route.name),
                    });

                    return link;
                });
        },
    },

    watch: {
        $route() {
            this.updateShowingAppBtnBack();
        },
    },

    created() {
        this.linksSource = [
            {
                text: 'view_site_menu_products',
                icon: 'shopping-cart',
                to: '/products',
                activeRoutes: ['Listing', 'ListingVendor', 'ListingProducts', 'Search', 'Product'],
                accordion: true,
                children: 'PRODUCTS',
            },
        ];
    },

    mounted() {
        this.updateShowingAppBtnBack();
    },

    methods: {
        updateShowingAppBtnBack() {
            this.showAppBtnBack = this.isMobileApp() && this.$router.currentRoute.name !== 'Homepage'
                && typeof window !== 'undefined' && window.history.length;
        },
        isMobileApp() {
            return this.$store.state.params.source === SOURCE.APP;
        },
        showCategories() {
            let output = true;
            const isNotPermittedParentRoute = this.$route.matched.find((route) => ['Cart'].includes(route.name));
            if (
                isNotPermittedParentRoute
                || !this.$store.state.ui.switch.web.header.navCategories
            ) {
                output = false;
            }

            return output;
        },
        onClickTheHeaderMenu(event) {
            const classListOutside = ['the-header-menu', 'container-fluid'];
            const classListClicked = [...event.target.classList];
            const hasClickedOutside = classListClicked
                .some((className) => classListOutside.indexOf(className) !== -1);

            if (hasClickedOutside) {
                this.drawer = false;
            }
        },
    },
};
</script>

<style lang="scss">
@import "../scss/05-components/the-header";
</style>
